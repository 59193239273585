import React from 'react'
import { Block, Content, Yoga } from 'gerami'

import { AboutQuery } from '../../../../../../graphql-types'
import GatsbyImage from 'gatsby-image'

type AboutLeadershipProps = Exclude<
  Exclude<AboutQuery['aboutPageInfo'], null | undefined>['frontmatter'],
  null | undefined
>['leadership']

const AboutLeadership: React.FC<AboutLeadershipProps> = ({
  title,
  leaders
}) => {
  return (
    <div>
      <Content
        transparent
        size="XL"
        className="left margin-vertical-very-big padding-vertical-very-big"
      >
        {title && (
          <Block first last={!leaders} className="center">
            <h1 className="">{title}</h1>
          </Block>
        )}

        {leaders && (
          <Block first={!title} last>
            <Yoga maxCol={3} className="center">
              {leaders.map((leader, i) => (
                <Content key={i}>
                  <GatsbyImage
                    fluid={{
                      aspectRatio: 4 / 3,
                      src: `${leader?.photo}?nf_resize=fit&w=480`,
                      srcSet: `
                        ${leader?.photo}?nf_resize=fit&w=480 480w,
                        ${leader?.photo}?nf_resize=fit&w=320 320w,
                        ${leader?.photo}?nf_resize=fit&w=160 320w
                      `,
                      sizes: `
                        (min-width: 36em) 33.3vw,
                        100vw
                      `
                    }}
                    className="bg-accent"
                  />
                  <h5 className="center padding-horizontal-normal padding-bottom-very-big">
                    <div className="padding-bottom-small">{leader?.name}</div>
                    <hr style={{ opacity: 0.42 }} />
                    <div className="subtitle padding-top-small bold">
                      {leader?.role?.toUpperCase()}
                    </div>
                  </h5>
                </Content>
              ))}
            </Yoga>
          </Block>
        )}
      </Content>
    </div>
  )
}

export default AboutLeadership
