import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'

import { AboutQuery } from '../../../../graphql-types'
import SEO from '../../../shared/components/seo/seo'
import Layout from '../../../shared/components/layout/layout'
import AboutLead from './components/about-lead/about-lead'
import AboutHistory from './components/about-history/about-history'
import AboutValues from './components/about-values/about-values'
import AboutStatementOfFaith from './components/about-statement-of-faith/about-statement-of-faith'
import AboutLeadership from './components/about-leadership/about-leadership'
import AboutStrategicAreas from './components/about-strategic-areas/about-strategic-areas'
import EmailRegistration from '../../../shared/components/email-registration/email-registration'
import HomeQuadLinks from '../home/components/home-quad-links/home-quad-links'

type AboutProps = {}

const About: React.FC<AboutProps> = ({}) => {
  const data = useStaticQuery<AboutQuery>(query)
  const {
    lead,
    history,
    values,
    statementOfFaith,
    leadership,
    strategicAreas
  } = data.aboutPageInfo?.frontmatter ?? {}
  const { quadLinks } = data.homePageInfo?.frontmatter ?? {}

  return (
    <>
      <SEO title="About" />

      <Layout>
        {!lead?.isEnabled ? null : <AboutLead {...lead} />}
        {!history?.isEnabled ? null : <AboutHistory {...history} />}
        {!values?.isEnabled ? null : <AboutValues {...values} />}
        {!strategicAreas?.isEnabled ? null : (
          <AboutStrategicAreas {...strategicAreas} />
        )}
        {!statementOfFaith?.isEnabled ? null : (
          <AboutStatementOfFaith {...statementOfFaith} />
        )}
        {!leadership?.isEnabled ? null : <AboutLeadership {...leadership} />}
        {!quadLinks?.isEnabled ? null : (
          <HomeQuadLinks {...quadLinks} onlyTop />
        )}
        <EmailRegistration />
      </Layout>
    </>
  )
}

export default About

const query = graphql`
  query About {
    aboutPageInfo: markdownRemark(
      fields: { slug: { eq: "/info/about-page/" } }
    ) {
      frontmatter {
        lead {
          isEnabled
          bg
          title
          description
        }
        history {
          isEnabled
          title
          description
        }
        values {
          isEnabled
          title
          description
        }
        strategicAreas {
          isEnabled
          title
          description
        }
        statementOfFaith {
          isEnabled
          title
          description
          attachment
        }
        leadership {
          isEnabled
          title
          leaders {
            photo
            name
            role
          }
        }
      }
    }
    homePageInfo: markdownRemark(fields: { slug: { eq: "/info/home-page/" } }) {
      frontmatter {
        quadLinks {
          isEnabled
          topLeftCover
          topLeftUrl
          topLeftTitle
          topRightCover
          topRightUrl
          topRightTitle
          bottomLeftCover
          bottomLeftUrl
          bottomLeftTitle
          bottomRightCover
          bottomRightUrl
          bottomRightTitle
        }
      }
    }
  }
`
