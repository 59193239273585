import React from 'react'
import { Block, Content } from 'gerami'

import { AboutQuery } from '../../../../../../graphql-types'
import AnotherSection from '../../../../components/another-section/another-section'
import Anchor from '../../../../../shared/components/anchor/anchor'

type AboutStatementOfFaithProps = Exclude<
  Exclude<AboutQuery['aboutPageInfo'], null | undefined>['frontmatter'],
  null | undefined
>['statementOfFaith']

const AboutStatementOfFaith: React.FC<AboutStatementOfFaithProps> = ({
  title,
  description,
  attachment
}) => {
  return (
    <div>
      <Content size="XXL" className="shade-L">
        <div className="bg-accent">
          <AnotherSection
            align="left"
            title={title}
            description={description}
          />
          {!attachment ? null : (
            <Block
              className="right"
              style={{ marginTop: `-4em`, padding: `0 4em 3em 0` }}
            >
              <Anchor
                to={attachment}
                download
                target="_blank"
                rel="noopener nofollow"
                className="gerami-button bg-blackish fg-white"
                style={{ borderColor: 'transparent' }}
              >
                Download Document
              </Anchor>
              <br />
              <br />
              <br />
            </Block>
          )}
        </div>
      </Content>
    </div>
  )
}

export default AboutStatementOfFaith
