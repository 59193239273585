import React from 'react'
import { Content } from 'gerami'

import { AboutQuery } from '../../../../../../graphql-types'
import AnotherSection from '../../../../components/another-section/another-section'

type AboutValuesProps = Exclude<
  Exclude<AboutQuery['aboutPageInfo'], null | undefined>['frontmatter'],
  null | undefined
>['values']

const AboutValues: React.FC<AboutValuesProps> = ({ title, description }) => {
  return (
    <div>
      <Content size="XXL">
        <AnotherSection
          bg="none"
          align="center"
          title={title}
          description={description}
        />
      </Content>
    </div>
  )
}

export default AboutValues
